<template>
  <div>
    <zy-title>
      {{ bindChartOptions.zyTitle }}
      <template slot="more">
        <zy-select
          :data="weakList"
          v-model="selectType"
          @selectType="getSelectData"
        >
        </zy-select>
      </template>
    </zy-title>
    <div class="charts">
      <env-charts
        height="190px"
        v-if="pmData.length > 0"
        v-bind="bindChartOptions"
        :pmData="pmData"
        :pmLimitData="pmLimitData"
      />
    </div>
  </div>
</template>

<script>
import components from "@/jxyh/components"
import { mapGetters } from "vuex"
import EnvCharts from "./env-charts"
export default {
  ...components(["ZyTitle", "ZySelect", "ZyChartBar"], { EnvCharts }),
  computed: {
    pmData() {
      const info = this.socketData[this.path]
      if (!info) {
        return {}
      }
      if (this.selectType == 7) {
        return info[`${this.bindChartOptions.leftKey}DayData`]?.rows
      }
      return info[`${this.bindChartOptions.leftKey}MonthData`]?.rows
    },
    ...mapGetters("socket", ["socketData"]),
    bindChartOptions() {
      return {
        ...this.$attrs,
      }
    },
    pmLimitData() {
      const info = this.socketData[this.path].limitMap
      if (!info) {
        return null
      }
      return info[`${this.bindChartOptions.leftKey}HighLimit`]
    },
  },
  data() {
    return {
      path: "hero/websocket/environmentalData",
      selectType: 7,
      weakList: [
        {
          label: "天",
          id: 7,
        },
        {
          label: "月",
          id: 30,
        },
      ],
    }
  },
  methods: {
    getSelectData(val) {
      this.selectType = val
    },
  },
}
</script>

<style lang="less" scoped>
.charts {
  height: 100%;
  width: 100%;
  margin-top: 10px;
}
</style>
