<template>
  <div class="historical">
    <zy-title>天气预报</zy-title>
    <div class="historical-weather">
      <div
        class="historical-weather-day"
        v-for="(card, index) in list"
        :key="index"
      >
        <div class="historical-weather-day-time">{{ card.date }}{{ card.tips || "" }}</div>
        <div class="historical-weather-day-weak">{{ getWeekday(card.date) }}</div>
        <img
          class="historical-weather-day-img"
          :src="card.icon"
        />
        <div class="historical-weather-day-heat">
          {{ `${card.tempMin}~${card.tempMax}℃    ${card.textDay}` }}
        </div>
        <div class="historical-weather-day-text">
          {{ card.windDirDay }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import components from "@/jxyh/components"
import { historicalWeather } from "@/jxyh/api/monitoring"
import axios from "@/utils/request"
import { mapGetters } from "vuex"
export default {
  ...components(["ZyTitle"]),
  data() {
    return {
      oldList: [],
      dailyList: [],
    }
  },
  computed: {
    list() {
      const dailyList = JSON.parse(JSON.stringify(this.dailyList))
      if (dailyList.length > 0) {
        dailyList[0].tips = "（今天）"
      }
      let oldList = []
      if (this.oldList.length > 0) {
        oldList = [this.oldList[this.oldList.length - 1]]
      }
      const list = JSON.parse(JSON.stringify([...oldList, ...dailyList]))
      for (let i = 0; i < list.length; i++) {
        const day = list[i]
        day.icon = require(`../../../../../assets/image/weather-icon-64/${day.iconDay}.png`)
      }
      return list
    },
    ...mapGetters(["lng", "lat"]),
  },
  created() {
    this.getHistoricalWeather()
  },
  methods: {
    getHistoricalWeather() {
      historicalWeather()
        .then((res) => {
          this.oldList = res.data || []
        })
        .catch((_) => {})
      const latlng = `${this.lng},${this.lat}`
      const url = `https://devapi.qweather.com/v7/weather/3d?location=${latlng}&key=aaeba401ce0043b9b5393db02cd50433`
      axios
        .get(url)
        .then((res) => {
          const list = res.daily || []
          this.dailyList = []
          list.forEach((day) => {
            this.dailyList.push({
              date: day.fxDate,
              tempMax: day.tempMax,
              textDay: day.textDay,
              windDirDay: day.windDirNight,
              tempMin: day.tempMin,
              iconDay: day.iconDay,
            })
          })
        })
        .catch((_) => {})
    },
    getWeekday(date) {
      const weekdays = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"]
      const day = new Date(date).getDay()
      return weekdays[day]
    },
  },
}
</script>

<style lang="less" scoped>
.historical {
  margin-top: 50px;
  &-weather {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    &-day {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
      border-right: 1px solid rgba(255, 255, 255, 0.1);
      &:first-child {
        border-left: 1px solid rgba(255, 255, 255, 0.1);
      }
      &-time {
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        margin-top: 4px;
        line-height: 16px;
      }
      &-weak {
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        line-height: 16px;
        margin-top: 10px;
      }
      &-img {
        width: 64px;
        height: 64px;
        margin-top: 24px;
      }
      &-heat {
        font-size: 16px;
        color: #ffffff;
        line-height: 16px;
        margin-top: 24px;
      }
      &-text {
        margin-top: 10px;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        line-height: 16px;
      }
    }
  }
}
</style>
