<!-- 环境质量 -->
<template>
  <div class="environment">
    <zy-title>
      环境质量汇总
      <template slot="more">
        <zy-select
          :data="selectList"
          v-model="type"
          @selectType="getEnvironmentSummary"
        >
        </zy-select>
      </template>
    </zy-title>
    <div class="environment-title">空气质量</div>
    <div
      v-for="(row, index) in airQuality"
      :key="`airQuality-${index}`"
      class="environment-row"
    >
      <div
        v-for="card in row"
        :key="card.name"
        class="environment-row-card"
      >
        <div class="environment-row-card-flot">
          <div
            class="environment-row-card-icon"
            :style="{ background: card.color }"
          ></div>
          <div class="environment-row-card-title">{{ card.label }}</div>
        </div>
        <div class="environment-row-card-flot">
          <div class="environment-row-card-value">{{ card.value }}</div>
          <div class="environment-row-card-unit">{{ card.unit }}</div>
        </div>
      </div>
    </div>
    <div class="environment-line"></div>
    <div class="environment-title">噪音</div>
    <div
      v-for="(row, index) in evaluate"
      :key="`evaluate-${index}`"
      class="environment-row"
    >
      <div
        v-for="card in row"
        :key="card.name"
        class="environment-row-card"
      >
        <div class="environment-row-card-flot">
          <img
            class="environment-row-card-img"
            :src="card.icon"
          />
          <div class="environment-row-card-title">{{ card.label }}</div>
        </div>
        <div class="environment-row-card-flot">
          <div class="environment-row-card-value">{{ card.value }}</div>
          <div class="environment-row-card-unit">{{ card.unit }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import components from "@/jxyh/components"
import { environmentSummary } from "@/jxyh/api/monitoring"
import { mapGetters } from "vuex"
export default {
  ...components(["ZyTitle", "ZySelect"]),
  data() {
    return {
      type: "year",
      selectList: [
        {
          label: "本年",
          id: "year",
        },
        {
          label: "30天",
          id: "month",
        },
        {
          label: "7天",
          id: "week",
        },
      ],
      info: {},
    }
  },
  computed: {
    airQuality() {
      return [
        [
          {
            label: "优秀",
            value: this.info.excellent || 0,
            unit: "天",
            color: "#63E881",
          },
          {
            label: "良好",
            value: this.info.good || 0,
            unit: "天",
            color: "#FFB822",
          },
        ],
        [
          {
            label: "轻度污染",
            value: this.info.lightPollution || 0,
            unit: "天",
            color: "#FC8A4A",
          },
          {
            label: "中度污染",
            value: this.info.moderatePollution || 0,
            unit: "天",
            color: "#FF3355",
          },
        ],
        [
          {
            label: "重度污染",
            value: this.info.heavyPollution || 0,
            unit: "天",
            color: "#980354",
          },
          {
            label: "严重污染",
            value: this.info.seriousPollution || 0,
            unit: "天",
            color: "#62001E",
          },
        ],
      ]
    },
    evaluate() {
      return [
        [
          {
            label: "达标",
            value: this.info.standard || 0,
            unit: "天",
            icon: require("@/jxyh/assets/images/icon_monitoring_suss.png"),
          },
          {
            label: "不达标",
            value: this.info.unStandard || 0,
            unit: "天",
            icon: require("@/jxyh/assets/images/icon_monitoring_error.png"),
          },
        ],
      ]
    },
    ...mapGetters(["devices"]),
  },
  watch: {
    devices: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && val.length) {
          this.getEnvironmentSummary()
        }
      },
    },
  },
  methods: {
    getEnvironmentSummary(type) {
      this.type = type
      environmentSummary({ type: this.type, serialNo: this.devices[0]?.id })
        .then((res) => {
          this.info = res.data || {}
        })
        .catch((_) => {})
    },
  },
}
</script>

<style lang="less" scoped>
.environment {
  &-title {
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
    margin-top: 10px;
  }
  &-row {
    margin-bottom: 10px;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: row;
    height: 48px;
    box-sizing: border-box;
    &-card {
      width: 100%;
      flex: 1;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 20px;
      color: #fff;
      & + & {
        border-left: 1px solid rgba(255, 255, 255, 0.1);
      }
      &-flot {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      &-icon {
        width: 12px;
        height: 12px;
        border-radius: 6px;
        margin-left: 4px;
      }
      &-img {
        width: 18px;
        height: 18px;
      }
      &-title {
        font-size: 14px;
        color: #ffffff;
        line-height: 21px;
        margin-left: 18px;
      }
      &-value {
        font-weight: bold;
        font-size: 24px;
        color: #ffffff;
        line-height: 30px;
      }
      &-unit {
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        margin-left: 5px;
      }
    }
  }
  &-title + &-row {
    margin-top: 20px;
  }
  &-line {
    width: 100%;
    height: 1px;
    background: #ffffff;
    opacity: 0.1;
    margin-top: 20px;
  }
  &-line + &-title {
    margin-top: 20px;
  }
  &-row + .zy-title {
    margin-top: 30px;
  }
}
</style>
