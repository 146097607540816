<template>
  <div class="monitoring-details">
    <zy-title>实时监测数据详情</zy-title>
    <div class="monitoring-details-bg">
      <div
        class="monitoring-details-bg-row"
        v-for="(row, index) in infoList"
        :key="index"
      >
        <render-card
          v-for="(card, index) in row"
          :key="card.label"
          :config="card"
          :isRight="index == 1"
        >
        </render-card>
        <!-- <div
          :class="['row', index == 1 ? 'row-reverse' : '']"
          v-for="(card, index) in row"
          :key="card.label"
        >
          <img :src="card.icon" />
          <div :class="['monitoring-details-card', index == 1 ? 'is-right' : '']">
            <div>
              <div>{{ card.value }}</div>
              <div>{{ card.unit }}</div>
            </div>
            <div>{{ card.label }}</div>
          </div>
        </div> -->
      </div>
      <div class="monitoring-details-center">
        <zy-img-gif
          :path="aqiInfo.gifName"
          :size="390"
        >
          <div class="monitoring-details-center-title">空气质量（AQI）</div>
          <div class="monitoring-details-center-value">
            <div>{{ aqi }}</div>
            <div class="monitoring-details-center-unit">μg/m³</div>
          </div>
          <div
            :class="['monitoring-details-center-button']"
            :style="{ background: aqiInfo.color }"
          >
            {{ aqiInfo.name }}
          </div>
        </zy-img-gif>
      </div>
      <div class="monitoring-details-bar">
        <monitoring-bar></monitoring-bar>
      </div>
    </div>
  </div>
</template>

<script>
import components from "@/jxyh/components"
import MonitoringBar from "./monitoring-bar.vue"
import { mapGetters } from "vuex"
import { environmentAQI } from "@/jxyh/api/monitoring"
// 卡片布局
function renderCard(h, ctx) {
  const { config, isRight } = ctx.props
  const { value, label, unit, icon, highLimit } = config
  // config 配置信息
  // highLimit 预警值
  // isRight 是否为右边

  //警告
  let waringRe = null
  if (value >= highLimit) {
    console.log(config)
    waringRe = h("div", {
      class: "waring",
    })
  }
  const iconRe = h("img", {
    attrs: {
      src: icon,
    },
  })
  const valueRe = h("div", [h("div", [value]), h("div", [unit])])
  const labelRe = h("div", [label])
  const textRe = h(
    "div",
    {
      //['monitoring-details-card', index == 1 ? 'is-right' : ''
      class: `monitoring-details-card ${isRight ? "is-right" : ""}`,
    },
    [valueRe, labelRe]
  )
  return h(
    "div",
    {
      class: `card row ${isRight ? "row-reverse" : ""}`,
    },
    [iconRe, textRe, waringRe]
  )
}
export default {
  components: {
    ...components(["ZyTitle", "ZyImgGif"]).components,
    MonitoringBar,
    renderCard: {
      functional: true,
      render: renderCard,
    },
  },
  data() {
    return {
      aqi: 0,
    }
  },
  computed: {
    aqiInfo() {
      let name = ""
      let gifName = ""
      let color = "#62001E"
      if (this.aqi <= 50) {
        name = "优秀"
        gifName = "monitoring/excellent"
        color = "#63E881"
      } else if (this.aqi > 50 && this.aqi <= 100) {
        name = "良好"
        gifName = "monitoring/good"
        color = "#FFB822"
      } else if (this.aqi <= 150) {
        name = "轻度污染"
        gifName = "monitoring/lightPollution"
        color = "#F26B1E"
      } else if (this.aqi <= 200) {
        name = "中度污染"
        gifName = "monitoring/moderatePollution"
        color = "#FF3355"
      } else if (this.aqi <= 300) {
        name = "重度污染"
        gifName = "monitoring/heavyPollution"
        color = "#980354"
      } else {
        name = "严重污染"
        gifName = "monitoring/seriousPollution"
        color = "#62001E"
      }
      return {
        name,
        gifName,
        color,
      }
    },
    infoList() {
      return [
        [
          {
            label: "温度",
            value: this.mainInfo?.temp || 0,
            unit: "℃",
            icon: require("@/jxyh/assets/images/img-wd.png"),
            highLimit: this.limitMap?.tempHighLevel || 0,
          },
          {
            label: "湿度",
            value: this.mainInfo?.wet || 0,
            unit: "%",
            icon: require("@/jxyh/assets/images/img-sd.png"),
            highLimit: this.limitMap?.wetHighLimit || 0,
          },
        ],
        [
          {
            label: "风速",
            value: this.mainInfo?.wind || 0,
            unit: "级",
            icon: require("@/jxyh/assets/images/img-fs.png"),
            highLimit: this.limitMap?.windHighLimit || 0,
          },
          {
            label: "风向",
            value: this.mainInfo?.direction || "暂无",
            unit: "",
            icon: require("@/jxyh/assets/images/img-fx.png"),
            highLimit: 0,
          },
        ],
        [
          {
            label: "噪音",
            value: this.mainInfo?.noice || 0,
            unit: "分贝",
            icon: require("@/jxyh/assets/images/img-zy.png"),
            highLimit: this.limitMap?.noiceHighLimit || 0,
          },
          {
            label: "PM2.5",
            value: this.mainInfo?.pm2point5 || 0,
            unit: "μg/m³",
            icon: require("@/jxyh/assets/images/img-pm2.5.png"),
            highLimit: this.limitMap?.pm2point5HighLimit || 0,
          },
        ],
        [
          {
            label: "TSP",
            value: this.mainInfo?.tsp || 0,
            unit: "μg/m³",
            icon: require("@/jxyh/assets/images/img-tsp.png"),
            highLimit: this.limitMap?.tspHighLimit || 0,
          },
          {
            label: "PM10",
            value: this.mainInfo?.pm10 || 0,
            unit: "μg/m³",
            icon: require("@/jxyh/assets/images/img-pm10.png"),
            highLimit: this.limitMap?.pm10HighLimit || 0,
          },
        ],
      ]
    },
    mainInfo() {
      return this.socketData["hero/websocket/environmentalData"]?.mainInfo
    },
    limitMap() {
      return this.socketData["hero/websocket/environmentalData"]?.limitMap
    },
    ...mapGetters(["devices"]),
    ...mapGetters("socket", ["socketData"]),
  },
  watch: {
    devices: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && val.length) {
          this.getEnvironmentAQI()
        }
      },
    },
  },
  methods: {
    getEnvironmentAQI() {
      environmentAQI({ serialNo: this.devices[0]?.id })
        .then((res) => {
          this.aqi = res.data?.aqi || 0
        })
        .catch((_) => {})
    },
  },
}
</script>

<style lang="less" scoped>
.monitoring-details {
  color: #ffffff;
  &-bar {
    position: absolute;
    left: 50%;
    bottom: 40px;
    transform: translateX(-50%);
  }
  &-bg {
    margin-top: 10px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 552px;
    &-row {
      width: 100%;
      height: 86px;
      margin-bottom: 10px;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      background-image: linear-gradient(270deg, rgba(46, 205, 235, 0.2) 0%, rgba(16, 31, 68, 0) 25%, rgba(16, 31, 68, 0) 75%, rgba(46, 205, 235, 0.2) 100%);
    }
  }
  &-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 390px;
    height: 390px;
    &-button {
      border-radius: 18px;
      width: 108px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      user-select: none;
    }
    &-title {
      font-size: 16px;
      color: #ffffff;
      line-height: 24px;
    }
    &-value {
      font-weight: bold;
      font-size: 64px;
      color: #ffffff;
      line-height: 83px;
      letter-spacing: 1px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }
    &-unit {
      font-weight: bold;
      font-size: 20px;
      color: #ffffff;
      line-height: 60px;
    }
  }
  &-card {
    display: flex;
    flex-direction: column;
    & > div:nth-child(1) {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      & > div:nth-child(1) {
        font-weight: bold;
        font-size: 26px;
        color: #ffffff;
        line-height: 33px;
      }
      & > div:nth-child(2) {
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        line-height: 25px;
      }
    }
    & > div:nth-child(2) {
      font-size: 14px;
      color: #66bbf9;
      line-height: 21px;
    }
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    width: 185px;
    height: 86px;
    position: relative;
    & > img {
      width: 64px;
      height: 64px;
    }
  }
  .row > img + div,
  div + .row > img {
    margin-left: 10px;
  }
  .row-reverse {
    flex-direction: row-reverse;
  }
  .is-right {
    & > div:nth-child(2) {
      text-align: end;
    }
  }
}
.waring {
  background: url("../../../assets/images/img_waring.webp");
  background-size: 185px 86px;
  position: absolute;
  left: 0;
  top: 0;
  width: 185px;
  height: 86px;
  animation: 3s infinite waring;
}
@keyframes waring {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
