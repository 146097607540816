<template>
  <div
    class="env-charts"
    :style="{ height: height }"
  >
    <div
      ref="charts"
      :style="{ height: height, width: '100%' }"
    ></div>
    <slot></slot>
  </div>
</template>

<script>
import * as echarts from "echarts"
export default {
  data() {
    return {
      myChart: null,
      type: 1,
      limitData: 0,
    }
  },
  watch: {
    pmData: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.updateCharts()
        })
      },
    },
  },
  props: {
    pmData: {
      type: Array,
      default() {
        return []
      },
    },
    pmLimitData: {
      type: Number,
      default: 0,
    },
    envName: {
      type: String,
      default: "",
    },
    chartType: {
      type: String,
      default: "line",
    },
    lineColor: {
      type: String,
      default: "#63E881",
    },
    height: {
      type: String,
      default: "214px",
    },
    overColor: {
      type: String,
      default: "",
    },
    // 自定义坐标标题
    defineTitle: {
      type: String,
      default: "μg/m³",
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    option() {
      return {
        title: {
          text: this.defineTitle,
          textStyle: {
            fontSize: 12,
            fontFamily: "SourceHanSansCN",
            fontWeight: 400,
            color: "#FFFFFF",
          },
          left: "0px",
        },
        tooltip: {
          show: this.showTooltip,
          trigger: "axis",
          borderWidth: 1,
          backgroundColor: "rgba(30,80,135,0.95)",
          borderColor: "#33D8FA",
          borderRadius: 4,
          padding: [10, 10],
          confine: true,
          textStyle: {
            fontFamily: "SourceHanSansCN",
            fontWeight: 400,
            color: "#ffffff",
            fontSize: 14,
          },
          axisPointer: {
            type: "line",
            lineStyle: {
              color: "#00C7FF",
              type: "solid",
            },
          },
          formatter(params) {
            const item1 = params[0] //一般问题
            let value = item1.data
            if (item1.data && item1.data.value) {
              value = item1.data.value
            }
            return `<p style="margin-bottom: 10px">${item1.axisValueLabel}<p>
                    <p>${item1.marker}${item1.seriesName} ${value} μg/m³<p>`
          },
        },
        xAxis: {
          type: "category",
          data: this.pmData.map((item) => {
            let a = Object.keys(item)
            return item[a[1]]
          }),
          boundaryGap: false,
          axisLabel: {
            interval: "auto",
            fontSize: "12px",
            fontFamily: "SourceHanSansCN",
            fontWeight: "400",
            color: "#B1B1B1",
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#FFFFFF",
              opacity: "0.2",
              type: "solid",
            },
          },
        },
        yAxis: {
          type: "value",
          max: Math.max(
            ...this.pmData.map((item) => {
              let a = Object.keys(item)
              let value = Math.ceil(item[a[0]])
              let length = String(value).length
              return Math.ceil(value / Math.pow(10, length - 1)) * Math.pow(10, length - 1)
            }),
            Math.ceil(Math.ceil(this.pmLimitData) / Math.pow(10, String(Math.ceil(this.pmLimitData)).length - 1)) * Math.pow(10, String(Math.ceil(this.pmLimitData)).length - 1)
          ),
          splitLine: {
            show: true,
            lineStyle: {
              color: "#FFFFFF",
              type: "dashed",
              opacity: "0.2",
            },
          },
          axisLabel: {
            fontSize: "12px",
            fontFamily: "SourceHanSansCN",
            fontWeight: "400",
            color: "#FFFFFF",
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#FFFFFF",
              opacity: "0.2",
              type: "solid",
            },
          },
        },
        grid: {
          show: false,
          top: 32,
          x: 34,
          x2: 32,
          height: "72%",
        },
        series: {
          name: `${this.envName}`,
          data: this.pmData
            .map((item) => {
              let a = Object.keys(item)
              return item[a[0]]
            })
            .map((item) => {
              //重点：超出规定值变色
              if (this.overColor && item > this.pmLimitData) {
                return {
                  value: item,
                  itemStyle: {
                    color: "#FC4A66",
                  },
                }
              }
              return item
            }),
          type: this.chartType,
          smooth: true,
          itemStyle: {
            color: this.lineColor,
          },
          areaStyle: {
            //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
            color: {
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: this.lineColor,
                },
                {
                  offset: 1,
                  color: this.lineColor + "00",
                },
              ],
            },
          },
          markLine: {
            symbol: "none", //去掉警戒线最后面的箭头
            silent: true,
            label: {
              position: "end", //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
              formatter: this.pmLimitData ? `预\n警\n线\n${this.pmLimitData}` : "",
              color: "#FF0000",
              fontSize: 12,
              fontFamily: "SourceHanSansCN",
              width: 100,
              lineHeight: 15,
            },
            data: [
              {
                lineStyle: {
                  //警戒线的样式  ，虚实  颜色
                  type: "dashed",
                  color: this.pmLimitData ? "#FF3939" : "",
                },
                yAxis: this.pmLimitData,
              },
            ],
          },
        },
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initCharts()
    })
  },
  methods: {
    initCharts() {
      var chartDom = this.$refs.charts
      this.myChart = echarts.init(chartDom, null, {
        devicePixelRatio: window.devicePixelRatio || 1,
      })
      this.myChart.setOption(this.option)
    },
    updateCharts() {
      this.myChart.setOption(this.option)
    },
  },
  beforeDestroy() {
    if (!this.myChart) {
      return
    }
    this.myChart.dispose()
    this.myChart = null
  },
}
</script>

<style lang="less" scoped>
.env-charts {
  position: relative;
  width: 100%;
  height: 100%;
  &-detail {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
