<template>
  <zy-layout>
    <zy-layout-column animation="left">
      <!-- 环境质量汇总 -->
      <environment></environment>
      <pm-info class="jxyh-margin" v-bind="bindPm10Options"></pm-info>
      <pm-info
        class="jxyh-margin"
        v-bind="bindPm25Options"
      ></pm-info>
    </zy-layout-column>
    <zy-layout-column animation="center">
      <monitoring-details></monitoring-details>
      <historical-weather></historical-weather>
    </zy-layout-column>
    <zy-layout-column animation="right">
      <zy-title>今日异常报警</zy-title>
      <zy-scroll-table
      style="margin-top: 10px;"
        :data="warningList"
        :height="280"
      >
        <zy-scroll-table-column
          label="报警设备"
          prop="machineName"
          width="177px"
        >
        </zy-scroll-table-column>
        <zy-scroll-table-column
          label="报警内容"
          prop="contentTitle"
          align="center"
          width="176px"
        ></zy-scroll-table-column>
        <zy-scroll-table-column
          label="报警时间"
          prop="createTime"
          align="center"
          width="180px"
        ></zy-scroll-table-column>
      </zy-scroll-table>
      <pm-info
        style="margin-top: 32px;"
        v-bind="bindTspOptions"
      ></pm-info>
      <pm-info
        class="jxyh-margin"
        v-bind="bindNoiceOptions"
      ></pm-info>
    </zy-layout-column>
  </zy-layout>
</template>

<script>
import Environment from "./components/environment.vue"
import HistoricalWeather from "./components/historicalWeather.vue"
import MonitoringDetails from "./components/monitoringDetails.vue"
import components from "@/jxyh/components"
import PmInfo from "./components/pm-info"
import { fetchEnvironmentalMonitoringalarm } from "@/api"
import { mapGetters } from "vuex"
export default {
  ...components(["ZyLayout", "ZyLayoutColumn", "ZyTitle", "ZySelect", "ZyScrollTable", "ZyScrollTableColumn"], { PmInfo, Environment, HistoricalWeather, MonitoringDetails }),
  data() {
    return {
      path: "hero/websocket/environmentalData",
      limitMap: {},
      deviceId: null,
      environmentalData: {},
      bindPm10Options: {
        zyTitle: "PM10",
        leftKey: "pm10",
      },
      bindPm25Options: {
        zyTitle: "PM2.5",
        leftKey: "pm2point5",
        lineColor: "#66BBF9",
      },
      bindTspOptions: {
        zyTitle: "TSP",
        leftKey: "tsp",
      },
      bindNoiceOptions: {
        zyTitle: "噪音",
        leftKey: "noice",
        lineColor: "#0360F5",
        overColor: "#FC4A66",
        chartType: "bar",
        defineTitle: "db",
      },
      warningList: [],
    }
  },
  computed: {
    ...mapGetters(["projectId", "devices"]),
  },
  watch: {
    devices: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && val.length) {
          this.deviceId = val[0]?.id
          this.connectSocket()
          this.getEnvironmentAlarm()
        } else {
          this.deviceId = null
        }
      },
    },
  },
  methods: {
    getEnvironmentAlarm() {
      fetchEnvironmentalMonitoringalarm(this.projectId, this.deviceId).then((res) => {
        this.warningList = res.data
      })
    },
    connectSocket() {
      this.$store.dispatch("socket/CONNECT_SOCKET", {
        path: this.path,
        values: [this.projectId, this.deviceId],
      })
    },
  },
  beforeDestroy() {
    this.$store.dispatch("socket/CLOSE_SOCKET", this.path)
  },
}
</script>

<style lang="less" scoped></style>
