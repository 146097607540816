import { get } from "@/utils/request"

// 获取项目目标
export function environmentSummary(params) {
  return get("/wss/environmentalMonitoring/environmentSummary", params)
}
// 历史天气
export function historicalWeather() {
  return get("/wss/environmentalMonitoring/historicalWeather")
}
// 环境空气质量指数接口
export function environmentAQI(params) {
  return get("/wss/environmentalMonitoring/getEnvironmentAQI", params)
}
